import React from "react";
import { Link, useNavigate } from "react-router-dom";
import iconoMapa from "../../img/iconoMap.png";
import Moment from "moment";
import moment from "moment";
const AgenciaData = ({ empresa }) => {
  const navigate = useNavigate();
  function titleCase(str) {
    return str
      .split(" ")
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())
      .join(" ");
  }
  empresa.direccion_web_comerciales = `${empresa.direccion_calle}, ${empresa.direccion_localidad} `;
  const nombre = empresa.name;
  const distancia = empresa.distancia.distance?empresa.distancia.distance.toFixed(0).toString() + "M":null;
  const distanciaN = empresa.distancia.distance;
  const distanciaK = (distanciaN / 1000).toFixed(2).toString() + "Km";
  let visita = empresa.fecha_de_visita;
  if (visita != undefined) {
    //console.log(hola2)
    const fechaDeVisita = moment(visita, "MMDDYYYY").add(20, "days");
    const fechaAhora = Moment().format("MM/DD/YYYY");
    const vistada = moment(fechaAhora).isAfter(fechaDeVisita);
    //console.log(numero>=24)
    if (vistada) {
      return (
        <>
          <div
            className="Agencia-Data-container"
            onClick={() => navigate("/agencia/" + empresa.hs_object_id)}
          >
            <div className="Visit-container">
              <div className="Visit-Title Visit-false">
                <h4>Sin visitar</h4>
                <small>{"(" + empresa.estado_agencies.value + ")"}</small>
              </div>
              <div className="Visit-Date">
                <small>
                  Última visita el{" "}
                  {visita.slice(3, 6) +
                    visita.slice(0, 2) +
                    visita.slice(5, 10)}
                </small>
                <p></p>
              </div>
            </div>
            <div className="direccion-container">
              <div className="Title-container">
                <h2 className="title-name">{titleCase(nombre)}</h2>
              </div>
              <div className="img-container">
                <img src={iconoMapa} />
                <p>
                  {empresa.direccion_web_comerciales
                    ? empresa.direccion_web_comerciales + " "
                    : "No tenemos registrado su direccion"}{" "}
                  ({distanciaN < 1000 ? distancia : distanciaK})
                </p>
              </div>
            </div>
          </div>
          <div className="buttons">
            <div className="button-container">
              <a
                href={`https://www.google.com/maps/place/${empresa.latitud+','+empresa.longitud}`}
                className=""
              >
                Ver ubicacion
              </a>
            </div>
            <div className="button-container infor">
              <Link to={`/agencia/${empresa.hs_object_id}`}>
                <p>Más información</p>
                <i className="fa-solid fa-chevron-right"></i>
              </Link>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="Agencia-Data-container"
            onClick={() => navigate("/agencia/" + empresa.hs_object_id)}
          >
            <div className="Visit-container">
              <div className="Visit-Title Visit-true">
                <h4>Visitado</h4>
                <small>{"(" + empresa.estado_agencies.value + ")"}</small>
              </div>
              <div className="Visit-Date">
                <small>
                  Última visita el{" "}
                  {visita.slice(3, 6) +
                    visita.slice(0, 2) +
                    visita.slice(5, 10)}
                </small>
              </div>
            </div>
            <div className="direccion-container">
              <div className="Title-container">
                <h2>{titleCase(nombre)}</h2>
              </div>
              <div className="img-container">
                <img src={iconoMapa} />
                <p>
                  {empresa.direccion_web_comerciales
                    ? empresa.direccion_web_comerciales
                    : "No tenemos registrado su direccion"}{" "}
                  ({distanciaN < 1000 ? distancia : distanciaK})
                </p>
              </div>
            </div>
          </div>
          <div className="buttons">
            <div className="button-container">
              <a
                href={`https://www.google.com/maps/place/${empresa.latitud+','+empresa.longitud}`}
                className=""
              >
                Ver ubicacion
              </a>
            </div>
            <div className="button-container infor">
              <Link to={`/agencia/${empresa.hs_object_id}`}>
                <p>Más información</p>
                <i className="fa-solid fa-chevron-right"></i>
              </Link>
            </div>
          </div>
        </>
      );
    }
  } else {
    return (
      <>
        <div
          className="Agencia-Data-container"
          onClick={() => navigate("/agencia/" + empresa.hs_object_id)}
        >
          <div className="Visit-container">
            <div className="Visit-Title Visit-false">
              <h4>Sin visitar</h4>
              <small>{"(" + empresa.estado_agencies.value + ")"}</small>
            </div>
            <div className="Visit-Date">
              <small></small>
            </div>
          </div>
          <div className="direccion-container">
            <div className="Title-container">
              <h2>{titleCase(nombre)}</h2>
            </div>
            <div className="img-container">
              <img src={iconoMapa} />
              <p>
                {empresa.direccion_web_comerciales
                  ? empresa.direccion_web_comerciales
                  : "No tenemos registrado su direccion"}{" "}
                ({distanciaN < 1000 ? distancia : distanciaK})
              </p>
            </div>
          </div>
        </div>
        <div className="buttons">
          <div className="button-container">
            <a
              href={`https://www.google.com/maps/place/${empresa.latitud+','+empresa.longitud}`}
              className=""
            >
              Ver ubicacion
            </a>
          </div>
          <div className="button-container infor">
            <Link to={`/agencia/${empresa.hs_object_id}`}>
              <p>Más información</p> <i className="fa-solid fa-chevron-right"></i>
            </Link>
          </div>
        </div>
      </>
    );
  }
};

export default AgenciaData;
